import request from '@/utils/request'

const api = {
  attachmentUrl: '/admincp/attachment/index',
  attachmentDelUrl: '/admincp/attachment/delete',
  attachmentMoreDelUrl: '/admincp/attachment/moredel'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.attachmentUrl,
    method: 'post',
    data: parameter
  })
}

export function del (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.attachmentDelUrl,
    method: 'post',
    data: parameter
  })
}

export function moreDel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.attachmentMoreDelUrl,
    method: 'post',
    data: parameter
  })
}
