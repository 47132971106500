<template>

  <a-card :bordered="true">
    <page-header-wrapper :title="false">  </page-header-wrapper>
    <a-form layout="inline">
      <a-form-item label="类型">
        <a-select v-model="queryParam.type" allowClear style="min-width:100px" :default-value="0" placeholder="请选择">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">图片</a-select-option>
          <a-select-option :value="2">视频</a-select-option>
          <a-select-option :value="3">文件</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态">
        <a-select v-model="queryParam.status" allowClear style="min-width:100px" :default-value="0" placeholder="请选择">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="2">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="$refs.table.refresh(true);onClearSelected()">查询</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="clearSearch()">重置</a-button>
      </a-form-item>
    </a-form>

    <div class="table-operator">
      <a-dropdown v-if="selectedRowKeys.length > 0" v-action:del>
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="moreDel">
            <a-icon type="delete"/>
            删除
          </a-menu-item>
        </a-menu>
        <a-button>
          批量操作
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>
    <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择&nbsp;<a style="font-weight: 600">{{
        selectedRowKeys.length }}</a>项&nbsp;&nbsp;
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>
    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :rowSelection="rowSelection"
      :scroll="{ x: 800 }"
      rowKey="key"
      size="default"
    >
      <span slot="action" slot-scope="text, record">
        <a v-action:del @click="handleDel(record)">删除</a>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { del, FetchList, moreDel } from '@/api/attachment'
import { formatterTime } from '@/utils/util'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      visible: false,
      confirmLoading: false,
      loading: false,
      title: '',
      mdl: null,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          width: '80px',
          title: '缩略图',
          dataIndex: 'aid',
          customRender: (text, row, index) => {
            const c = process.env.VUE_APP_API_BASE_URL + '/web/attr/show?attr_id=' + text
            return <img src={c} style={{ width: '50px', height: '50px', borderRadius: '1–0%' }}></img>
          }
        },
        {
          title: '文件名称',
          dataIndex: 'filename'
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: (text, row, index) => {
            if (text === 1) {
              return '图片'
            } else if (text === 3) {
              return '视频'
            } else if (text === 3) {
              return '文件'
            }
          }
        },
        {
          title: '文件大小',
          dataIndex: 'filesize',
          sorter: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          sorter: true,
          customRender: (val) => {
            return val === 1 ? '启用' : '禁用'
          }
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'createTime',
          sorter: true,
          customRender: (val) => {
            return formatterTime(val)
          }
        }, {
          title: '操作',
          width: '80px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        var searchParam = []

        searchParam = this.getCommonOperate(searchParam, ['=__status', '=__type'])

        return FetchList(Object.assign(parameter, searchParam))
          .then(res => {
            return res.result
          })
      }
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    getCommonOperate (searchParam, fileds) {
      for (var key in this.queryParam) {
        var value = this.queryParam[key]
        if (typeof value !== 'number') {
          if (value === undefined || value === '' || value === null) {
            continue
          }
          value = value.toString().trim()
          if (value.length === 0) continue
        } else {
          if (value === 0) {
            continue
          }
        }
        for (var j = 0; j < fileds.length; j++) {
          var array = fileds[j].split('__')
          if (array[1] === key) {
            searchParam[fileds[j]] = value
          }
        }
      }
      return searchParam
    },
    handleDel (record) {
      const self = this
      this.$confirm({
        title: '确定要删除吗？',
        content: '删除后将不可恢复，请谨慎操作！',
        okText: '确认',
        cancelText: '取消',
        onOk () {
          del(record)
            .then(res => {
              if (res.code === 1) {
                self.$refs.table.refresh(true)
                self.$message.info(res.message)
              } else {
                self.$message.error(res.message)
              }
            })
        }

      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    moreDel () {
      const self = this
      const arr = []
      if (this.selectedRows.length > 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          if (this.selectedRows[i].aid > 1) {
            arr.push(this.selectedRows[i].aid)
          }
        }
        moreDel({ 'aids': arr.join(',') })
          .then(res => {
            if (res.code === 1) {
              self.$refs.table.refresh()
              this.selectedRowKeys = []
              this.selectedRows = []
              self.$message.info(res.message)
            } else {
              self.$message.error(res.message)
            }
          })
      }
    },
    onClearSelected () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    clearSearch () {
      this.queryParam = {}
      this.onClearSelected()
    }
  },
  watch: {}
}
</script>
